import React from "react";
import { Row, Col } from "antd";

import {
  Layout,
  SEO,
  BannerStandard,
  Map,
  ContactInfo,
  ContainerBackgroundWhite,
  ContainerContentFixed,
  ContactForm,
} from "../components";
import { InternalLinkTypes } from "../components/links";

const Contact = () => (
  <Layout pageLink={InternalLinkTypes.Contact}>
    <SEO title={"Contact Us"} />

    <BannerStandard title={"Contact Us"} subTitle={"Email / Chat / Visit"} />
    <ContainerBackgroundWhite>
      <ContainerContentFixed>
        <Row gutter={32}>
          <Col md={12}>
            <ContactInfo />
          </Col>
          <Col md={12}>
            <ContactForm />
          </Col>
        </Row>
      </ContainerContentFixed>
    </ContainerBackgroundWhite>
  </Layout>
);

export default Contact;
